<!--统计报表-月度报告-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :rules="formRule" ref="form" :model="filter" size="mini">
        <el-form-item prop="AcquitDateBegin">
          <el-date-picker
            type="date"
            v-model="filter.AcquitDateBegin"
            placeholder="开始时间"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="AcquitDateEnd">
          <el-date-picker
            type="date"
            v-model="filter.AcquitDateEnd"
            placeholder="结束时间"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="customerId">
          <el-select
            v-model="filter.customerId"
            filterable
            placeholder="选择单位"
          >
            <el-option
              v-for="item in customers"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="exportMonthlyReport"
            :loading="downloading"
            >导出报告</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="#" align="center" width="55" />
        <el-table-column
          prop="BeginDate"
          label="开始时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EndDate"
          label="结束时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CreatedAt"
          label="生成时间"
          align="center"
          header-align="center"
        />
        <el-table-column label="操作" align="center" header-align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleLook(scope.row)"
              >查看</el-button
            >
            <!-- <el-button type="text" size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            > -->
            <!-- <i
              title="删除"
              @click="handleDelRecord(scope.row)"
              class="opt el-icon-delete-solid"
            /> -->
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="操作"
          fixed="right"
          align="center"
          header-align="center"
        >
          <el-button
            type="text"
            size="small"
            @click="exportMonthlyReport"
            :loading="downloading"
            >导出月度报告</el-button
          >
        </el-table-column> -->
      </el-table>
    </el-col>
    <monthly-report
      ref="notificationRef"
      :show="isShowNotificationDialog"
      :showData="isShowNotificationDialogData"
      @on-close="close"
    ></monthly-report>
  </section>
</template>

<script>
import { saveAs } from "file-saver";
import { mapState } from "vuex";
import { join, parseTime, base64ToBlob } from "@/util/index";
import monthlyReport from "@/component/MonthlyReport";

export default {
  components: { monthlyReport },
  data() {
    return {
      isShowNotificationDialog: false, // 是否显示月度报表
      isShowNotificationDialogData: {},
      loading: false,
      downloading: false,
      datalist: [],
      filter: {
        customerId: undefined,
        WithAreaLocaleReport: true
      },
      customers: [],
      formRule: {
        AcquitDateBegin: [{ required: true, message: "请选择开始时间", trigger: "change" }],
        AcquitDateEnd: [{ required: true, message: "请选择结束时间", trigger: "change" }],
        customerId: [{ required: true, message: "请选择单位", trigger: "change" }]
      }
    };
  },
  computed: {
    ...mapState(["clientHeight", "customerTree"]),
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, { label: "Org", checkStrictly: true }),
    }),
  },
  created() {
    this.getCustomersList();
    this.getList();
  },
  methods: {
    dateFormat(r, c) {
      return parseTime(r[c.property], "{y}-{m}");
    },
    getList() {
      this.$post("admin/queryCustomerArchive", {})
        .then((res) => {
          this.datalist = res;
        })
        .catch(() => {});
    },
    getCustomersList() {
      this.$post("admin/queryCustomer", {page: 1, perPage: 10000})
        .then((res) => {
          this.customers = res.content;
        })
        .catch(() => {});
    },
    getThisMonth() {
      const day = new Date();
      const yy = day.getFullYear();
      const mm =
        day.getMonth() + 1 < 10
          ? "0" + (day.getMonth() + 1)
          : day.getMonth() + 1;
      const dd = day.getDate() < 10 ? "0" + day.getDate() : day.getDate();
      this.filter.AcquitDateBegin = `${yy}-${mm}-01`;
      this.filter.AcquitDateEnd = `${yy}-${mm}-${dd}`;
      // this.datalist.push({ Month: `${yy}-${mm}` });
    },
    exportMonthlyReport: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.downloading = true;
          this.$post("admin/listDataDetectorDailyStats", this.filter)
            .then((res) => {
              this.downloading = false;
              this.isShowNotificationDialog = true;
              this.isShowNotificationDialogData = JSON.parse(JSON.stringify(res));
              this.isShowNotificationDialogData.beginDate = this.filter.AcquitDateBegin;
              this.isShowNotificationDialogData.endDate = this.filter.AcquitDateEnd;
              this.isShowNotificationDialogData.customerId = this.filter.customerId;
            })
            .catch(() => {
              this.downloading = false;
            });
        }
      })
    },
    handleLook(row) {
      this.stringToBlob(row.Archive);
    },
    stringToBlob(str) {
      let blob = base64ToBlob(str);
      saveAs(blob, `月度报告${parseTime(new Date())}.docx`);
    },
    handleEdit(row) {
      // document.getElementById('monthlyReportDialog').innerHTML = row.Archive
      // this.$refs.notificationRef.$refs.monthlyReportDialog.innerHTML = row.Archive
      this.isShowNotificationDialog = true;
      console.log(this.$refs.notificationRef);
      this.$refs.notificationRef.setcont(row.Archive);
    },
    close() {
      this.isShowNotificationDialog = false;
      this.getList();
    },
    handleDelRecord(row) {
      this.$post("admin/removeCustomerArchive", { Id: row.Id })
        .then((res) => {})
        .catch(() => {});
    },
  },
};
</script>
