<template>
  <el-dialog
    class="notification_dialog"
    :visible="show"
    width="75%"
    @close="handleClose"
    center
  >
    <div
      class="notification_dialog_content"
      id="monthlyReportDialog"
      ref="monthlyReportDialog"
    >
      <h2>{{ title }}</h2>
      <h4>一、餐饮企业在线监测情况汇总：</h4>
      <p>{{ desc }}</p>
      <ol>
        <li v-for="(item, i) in showData.areaReports" :key="i">
          <h5>{{ item.AreaName }}</h5>
          <el-table
            :data="item.LocaleReports"
            border
            size="mini"
            style="width: 100%"
          >
            <el-table-column prop="LocaleName" label="监测点"></el-table-column>
            <el-table-column prop="Mn" label="设备编号"></el-table-column>
            <el-table-column
              prop="ExceedStandardDays"
              label="超标天数"
            ></el-table-column>
            <el-table-column
              prop="StoppedBusinessDays"
              label="歇业天数"
            ></el-table-column>
            <el-table-column
              prop="FilterAbnormallyUsedDays"
              label="净化器未正常使用天数"
            ></el-table-column>
            <el-table-column
              prop="DetectorProblemDays"
              label="监测仪故障天数"
            ></el-table-column>
            <el-table-column label="备注">
              <template slot-scope="scope">
                <el-input
                  class="input-box"
                  size="mini"
                  v-model="scope.row.Value"
                >
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </li>
        <li>
          <h5>在线率及超标率统计</h5>
          <el-table
            :data="showData.sumGroupByDates"
            border
            size="mini"
            style="width: 100%"
          >
            <el-table-column prop="AcquitDate" label="日期"></el-table-column>
            <el-table-column prop="Total" label="设备总数"></el-table-column>
            <el-table-column prop="ComplianceCount" label="达标数"></el-table-column>
            <el-table-column prop="CompliancePercent" label="达标率(%)"></el-table-column>
            <el-table-column prop="ExceedStandardCount" label="超标数"></el-table-column>
            <el-table-column prop="ExceedStandardPercent" label="超标率(%)"></el-table-column>
            <el-table-column prop="OnlineCount" label="在线数"></el-table-column>
            <el-table-column prop="OnlinePercent" label="在线率(%)"></el-table-column>
            <el-table-column prop="FilterProblemCount" label="净化器不工作数"></el-table-column>
            <el-table-column prop="FilterProblemPercent" label="净化器不工作率(%)"></el-table-column>
            <el-table-column prop="DetectorProblemCount" label="歇业数"></el-table-column>
            <el-table-column prop="DetectorProblemPercent" label="歇业率(%)"></el-table-column>
            <el-table-column prop="StoppedBusinessCount" label="监测仪故障数"></el-table-column>
            <el-table-column prop="StoppedBusinessPercent" label="监测仪故障率(%)"></el-table-column>
          </el-table>
        </li>
      </ol>
      <h4>二、监管区域油烟状况分析</h4>
      <ol>
        <li>
          <h5>改善情况</h5>
          <el-input
            type="textarea"
            v-model="situation"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
          ></el-input>
        </li>
        <li>
          <h5>目前存在的问题：</h5>
          <el-input
            type="textarea"
            v-model="problem"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
          ></el-input>
        </li>
        <li>
          <h5>改善建议：</h5>
          <el-input
            type="textarea"
            v-model="proposal"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
          ></el-input>
        </li>
      </ol>
      <div class="date_box">日期：{{ today }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button type="primary" @click="downloadPdf" :loading="downloading"
        >生成并下载pdf</el-button
      > -->
      <el-button @click="exportWord" type="primary" :loading="downloading"
        >导出为word</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { parseTime } from "@/util/index.js";
export default {
  props: {
    // 是否显示一点一挡
    show: {
      type: Boolean,
      default: false,
    },
    showData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      downloading: false, // 生成并下载pdf按钮 加载状态
      checkList: [],
      tableData: [],
      today: "",
      title: "餐饮油烟在线监测报告",
      desc: "基本信息及超标报警情况：",
      situation: "",
      problem: "",
      proposal: "",
    };
  },
  created() {
    this.getToday();
  },
  methods: {
    /**
     * @description 生成并下载pdf
     */
    downloadPdf() {
      this.downloading = true;
      this.ExportSavePdf(this.$refs.monthlyReportDialog, "月度报告").then(
        () => {
          setTimeout(() => {
            this.downloading = false;
            this.handleClose();
          }, 500);
        }
      );
    },

    /**
     * @description 关闭对话框
     */
    handleClose() {
      this.$emit("on-close", false);
    },
    getToday() {
      const day = new Date();
      const yy = day.getFullYear();
      const mm = day.getMonth() + 1;
      const dd = day.getDate();
      this.today = `${yy}年${mm}月${dd}日`;
    },
    // 点击导出word
    exportWord: function () {
      this.downloading = true;
      let that = this;
      // 读取并获得模板文件的二进制内容
      JSZipUtils.getBinaryContent("input.docx", function (error, content) {
        // input.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
        // 抛出异常
        if (error) {
          throw error;
        }

        // 创建一个PizZip实例，内容为模板的内容
        let zip = new PizZip(content);
        // 创建并加载docxtemplater实例对象
        let doc = new docxtemplater().loadZip(zip);
        // 设置模板变量的值
        doc.setData({
          areaReports: that.showData.areaReports, //接口返回数据（word需要填充的真是数据）
          sumGroupByDates: that.showData.sumGroupByDates, //接口返回数据（word需要填充的真是数据）
          situation: that.situation,
          problem: that.problem,
          proposal: that.proposal,
          title: that.title,
          desc: that.desc,
          today: that.today,
        });

        try {
          // 用模板变量的值替换所有模板变量
          doc.render();
        } catch (error) {
          // 抛出异常
          let e = {
            message: error.message,
            name: error.name,
            stack: error.stack,
            properties: error.properties,
          };
          console.log(JSON.stringify({ error: e }));
          throw error;
        }

        // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
        let out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        // var innerHtml = document.getElementById('monthlyReportDialog').innerHTML
        // console.log(innerHtml)
        // console.log(out) // out 是blob对象
        // FileReader 对象允许Web应用程序异步读取存储在用户计算机上的文件（或原始数据缓冲区）的内容，
        // 使用 File 或 Blob 对象指定要读取的文件或数据。
        // readAsDataURL 异步读取文件内容，结果用data:url的字符串形式表示
        var reader = new FileReader();
        reader.readAsDataURL(out);

        reader.onload = function (e) {
          // console.log(reader)
          const par = {
            BeginDate: that.showData.beginDate,
            EndDate: that.showData.endDate,
            CustomerIdObject: that.showData.customerId,
            // Archive: innerHtml
            Archive: reader.result,
          };
          that.$post("admin/createCustomerArchive", par).then(() => {
            setTimeout(() => {
              saveAs(out, `月度报告${parseTime(new Date())}.docx`);
              that.downloading = false;
              that.handleClose();
            }, 500);
          }).catch(() => {
            that.downloading = false;
          })
        };
        // 将目标文件对象保存为目标类型的文件，并命名
        // saveAs(out, `月度报告${parseTime(new Date())}.docx`);
      });
    },
    setcont(cont) {
      console.log(document.getElementById("monthlyReportDialog"));
      document.getElementById("monthlyReportDialog").innerHTML = cont;
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox_group_box {
  font-size: inherit;
  .el-checkbox {
    display: block;
  }
}

.notification_dialog {
  .notification_dialog_content {
    font-size: 13px;
    h2 {
      text-align: center;
    }
    h4 {
      font-size: 14px;
      margin: 10px 0 5px;
    }

    h5 {
      font-size: 13px;
      margin: 5px 0;
    }

    p {
      margin: 5px 0;
    }

    .date_box {
      text-align: right;
      padding: 10px 0;
      margin-top: 20px;
      font-weight: bold;
      font-weight: 15px;
    }
  }
}
</style>
